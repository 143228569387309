import React, { useState } from 'react';
import './App.css';
import { Helmet } from 'react-helmet';
import { Home, Settings, Info } from 'lucide-react'; 

import { Mail, Phone } from 'lucide-react'; 

const App = () => {
  const [language, setLanguage] = useState('en'); 

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'bg' : 'en');
  };

  const content = {
    en: {
      header: {
        title: 'Reliable Wholesale & Logistics Solutions',
        nav: {
          home: 'Home',
          services: 'Our Services',
          about: 'About Us',
        },
      },
      hero: {
        title: 'Your Trusted Partner in Industrial Supply & Logistics',
        description: [
          'VF Transport is dedicated to delivering innovative logistics solutions and wholesale distribution of premium tap products and semi-finished materials. We work hand-in-hand with manufacturers worldwide, ensuring reliable and efficient services that support seamless production and supply chain processes.',
          'Whether you’re optimizing your supply chain or sourcing high-quality materials for industrial machinery, we provide customized solutions that enhance your operations and ensure your success in a competitive global market.',
          'Partner with us today to experience the benefits of dependable logistics services and premium industrial supplies designed to meet the unique needs of your business.',
          ],
          button: 'Learn More',
      },
      contact: {
        title: 'Contact Us',
        description: 'For any further information or inquiries regarding our logistics services, feel free to reach out to us. Our team is here to assist you with your supply chain needs and provide solutions tailored to your business.',
        emailDesc: 'Email: ',
        email: 'info@vftransport.eu',
        phoneDesc: 'Phone: ',
        phone: '+359 876339312',
      },
      services: {
        title: 'Our Services',
        items: [
          {
            title: 'Logistics Transport',
            description:
              'Our comprehensive logistics solutions are designed to ensure that each industrial component and semi-finished material reaches its destination securely and promptly, no matter where in the world it’s headed. With a well-maintained fleet capable of handling diverse transportation needs, VFTransport excels in providing end-to-end efficiency, from the point of origin to the final delivery. Our team of experienced logistics experts coordinates every detail, allowing us to manage complex supply routes, minimize delays, and ensure peace of mind for our clients at every step.',
            imageUrl: '/Assets/LogisticsTransport.jpeg',
            },
          {
            title: 'Wholesale Distribution',
            description:
              'VFTransport is your trusted partner for high-quality materials essential to the manufacturing of tap products and industrial equipment. We are committed to delivering premium materials at competitive prices, supported by a reliable supply chain that empowers manufacturers to scale production and stay ahead in a competitive market. From initial order to final delivery, VFTransport handles each distribution step with precision, meeting the unique requirements of manufacturers and helping to enhance their production capabilities.',
            imageUrl: '/Assets/WholesaleDistribution.jpeg',
            },
          {
            title: 'Supply Chain Optimization',
            description:
              'At VFTransport, we recognize the importance of an optimized supply chain in driving operational success. By refining each stage of the supply chain—from procurement and sourcing to transportation and final delivery—we help our partners achieve seamless operations and reduced costs. Our team applies innovative logistics solutions to integrate and streamline workflows, allowing for greater flexibility, reduced inventory waste, and a more sustainable approach that drives long-term growth.',
            imageUrl: '/Assets/SupplyChainOptimization.jpeg',
            },
          {
            title: 'Wholesale Trade of Faucet Products',
            description:
              'Specializing in the wholesale trade of faucet products, VFTransport offers an extensive selection of top-quality solutions designed to meet the rigorous demands of both industry and distributors. Our range includes everything from standard tap components to specialized fittings, each carefully sourced to ensure quality and reliability. VFTransport’s wholesale division is dedicated to providing tailored solutions that support our clients’ needs, helping them to optimize inventory, reduce costs, and gain a competitive edge.',
            imageUrl: '/Assets/FaucetWholesaleTrade.jpeg',
            },
          {
            title: 'Photovoltaic Panels and Electronic Components',
            description:
              'VFTransport specializes in the wholesale distribution of high-quality photovoltaic panels and electronic components, including products like SSDs, RAM, hard disks, and more. We cater to the increasing demand for both renewable energy solutions and advanced electronics. Our photovoltaic product range includes efficient modules, inverters, mounting systems, and other essential components for complete solar installations. In addition, we provide a wide selection of electronic components such as SSDs, RAM, hard drives, and other essential parts for various technological applications. We are committed to supporting green energy initiatives and the electronics sector by offering competitively priced, sustainable solutions for manufacturers and distributors, helping them meet both energy and technological needs while reducing environmental impact.',
            imageUrl: '/Assets/PhotovoltaicAndElectronicComponents.jpeg',
          }
        ],
      },
      about: {
        title: 'About VFTransport',
        description: [
          'VFTransport is a leading company specializing in logistics transport and the wholesale distribution of high-quality tap products, as well as semi-finished materials that are essential for the production of industrial machinery.', 
          'With a robust and well-established reputation, we pride ourselves on offering tailored logistics solutions that support the diverse and complex needs of our clients across various industries.',
          'Our expertise extends to handling the intricate requirements of supply chain management, ensuring the timely delivery of premium products to manufacturers worldwide.',
          'Through a commitment to innovation, reliability, and efficiency, we strive to optimize the production processes of our customers, helping them maintain smooth operations and meet their production goals.',
          'Our focus is to deliver unparalleled services that drive success in the ever-evolving global marketplace.',
        ],
      },
      footer: {
        info: {
          title: 'VF Transport',
          address: '81 Buxton Brothers Blvd., floor 1, Office 3, Manastirski livadi Residential Quarter Sofia, Bulgaria, 1618',
          vatNumber: 'VAT Number: 12345678901',
          commercialRegistry: 'Commercial Register: 12345 REA - Sofia Municipality',
        },
        links: {
          privacy: 'Privacy Policy',
          terms: 'Terms and Conditions',
        },
        copyright: '© 2024 VFTransport. All rights reserved.',
      },
    },
    bg: {
      header: {
        title: 'Надеждни Едри Търговски и Логистични Решения',
        nav: {
          home: 'Начало',
          services: 'Нашите Услуги',
          about: 'За Нас',
        },
      },
      hero: {
        title: 'Вашият Надежден Партньор в Промишлените Доставки и Логистика',
        description: [
          'VFTransport е посветен на предоставянето на иновативни логистични решения и едро разпространение на премиум продукти за кранове и полуготови материали. Ние работим ръка за ръка с производители по целия свят, осигурявайки надеждни и ефективни услуги, които подкрепят безпроблемни производствени и вериги за доставки.',
          'Независимо дали оптимизирате веригата си за доставки или източвате висококачествени материали за индустриални машини, ние предоставяме персонализирани решения, които подобряват вашите операции и осигуряват успех в конкурентния глобален пазар.',
          'Сътрудничете с нас днес, за да изпитате ползите от надеждни логистични услуги и премиум индустриални доставки, проектирани да отговорят на уникалните нужди на вашия бизнес.'
        ],
        button: 'Научете Повече',
      },
      contact: {
        title: 'Свържете се с нас',
        description: 'Нашите логистични решения са създадени с цел да осигурят надеждна и навременна доставка на индустриални компоненти и полуготови материали до всяка точка по света. Разполагаме с добре поддържан автопарк, способен да обслужва разнообразни транспортни нужди, като по този начин VFTransport осигурява ефективност от източника до крайната дестинация. Нашият опитен екип от логистични специалисти координира всеки детайл, за да се справя с комплексни логистични маршрути и да минимизира закъсненията, осигурявайки спокойствие за нашите клиенти на всеки етап.',
        emailDesc: 'Имейл: ',
        email: 'info@vftransport.eu',
        phoneDesc: 'Телефон: ',
        phone: '+359 876339312',
      },
      services: {
        title: 'Нашите Услуги',
        items: [
          {
            title: 'Логистичен Транспорт',
            description:
              'Нашите логистични решения са създадени с цел да осигурят надеждна и навременна доставка на индустриални компоненти и полуготови материали до всяка точка по света. Разполагаме с добре поддържан автопарк, способен да обслужва разнообразни транспортни нужди, като по този начин VFTransport осигурява ефективност от източника до крайната дестинация. Нашият опитен екип от логистични специалисти координира всеки детайл, за да се справя с комплексни логистични маршрути и да минимизира закъсненията, осигурявайки спокойствие за нашите клиенти на всеки етап.',
            imageUrl: '/Assets/LogisticsTransport.jpeg',
            },
          {
            title: 'Едро Разпространение',
            description:
              'VFTransport е вашият надежден партньор за висококачествени материали, необходими за производството на кранови продукти и промишлено оборудване. Ние предлагаме първокласни материали на конкурентни цени, подкрепени от надеждна верига за доставки, която позволява на производителите да увеличат производството си и да останат конкурентоспособни на пазара. От първоначалната поръчка до финалната доставка, VFTransport контролира всеки етап с прецизност, за да отговори на уникалните изисквания на производителите и да подобри техните производствени възможности.',
            imageUrl: '/Assets/WholesaleDistribution.jpeg',
            },
          {
            title: 'Оптимизация на Веригата за Доставки',
            description:
              'В VFTransport осъзнаваме колко важна е оптимизацията на веригата за доставки за оперативния успех. Чрез подобряване на всеки етап от веригата – от доставката на суровини до крайния транспорт – ние помагаме на нашите партньори да постигнат безпроблемни операции и намалени разходи. Нашият екип прилага иновативни логистични решения, които позволяват гъвкавост, намаляване на излишъците и по-устойчив подход, който подпомага дългосрочния растеж.',
            imageUrl: '/Assets/SupplyChainOptimization.jpeg',
            },
          {
            title: 'Търговия на Едро с Продукти за Смесители',
            description:
              'Специализирани в търговията на едро с продукти за смесители, VFTransport предлага широка селекция от решения, които отговарят на изискванията както на индустрията, така и на дистрибуторите. Ние предлагаме всичко от стандартни компоненти до специализирани фитинги, като всяко решение е внимателно подбрано, за да гарантира качество и надеждност. Отделът за търговия на едро на VFTransport е посветен на предоставянето на решения, които подпомагат нуждите на клиентите, оптимизират инвентара и осигуряват конкурентни предимства',
            imageUrl: '/Assets/FaucetWholesaleTrade.jpeg',
            }, 
            {
              title: 'Фотоволтаични панели и електронни компоненти',
              description:
                'VFTransport се специализира в търговия на едро с фотоволтаични панели с високо качество и електронни компоненти, включително продукти като SSD, RAM, твърди дискове и други. Ние отговаряме на нарастващото търсене както на решения за възобновяема енергия, така и на напреднали електронни технологии. Нашият асортимент за фотоволтаични продукти включва ефективни модули, инвертори, монтажни системи и други съществени компоненти за цялостни соларни инсталации. Освен това предлагаме широк избор от електронни компоненти като SSD, RAM, твърди дискове и други необходими части за различни технологични приложения. Ние сме ангажирани с подкрепа на инициативите за зелена енергия и технологичния сектор, като предлагаме конкурентни цени и устойчиви решения за производители и дистрибутори, което им позволява да отговорят на енергийните и технологични нужди, като намаляват екологичния отпечатък.',
              imageUrl: '/Assets/PhotovoltaicAndElectronicComponents.jpeg',
            } 
        ],
      },
      about: {
        title: 'За VFTransport',
        description: [
          'VFTransport е водеща компания, специализирана в логистичен транспорт и едро разпространение на висококачествени продукти за кранове, както и полуготови материали, които са съществени за производството на индустриални машини.',
          'С надеждата и утвърдената си репутация, ние се гордеем с предлагането на персонализирани логистични решения, които подкрепят разнообразните и сложни нужди на нашите клиенти в различни индустрии.',
          'Нашият опит обхваща справянето със сложните изисквания на управлението на веригата за доставки, осигурявайки навременна доставка на премиум продукти на производители по целия свят.',
          'Чрез ангажимент към иновациите, надеждността и ефективността, ние се стремим да оптимизираме производствените процеси на нашите клиенти, помагайки им да поддържат безпроблемни операции и да постигат своите производствени цели.',
          'Нашият фокус е да предоставяме ненадминати услуги, които водят към успех в постоянно променящия се глобален пазар.',
        ],
      },
      footer: {
        info: {
          title: 'VFTransport',
          address: '81 Булевард Братя Бъкстон, етаж 1, офис 3, Жилищен квартал Манастирски ливади, София, България, 1618',
          vatNumber: 'Номер на ДДС: 12345678901',
          commercialRegistry: 'Регистър на търговците: 12345 REA - Софийска Община',
        },
        links: {
          privacy: 'Политика за поверителност',
          terms: 'Условия за ползване',
        },
        copyright: '© 2024 VFTransport. Всички права запазени.',
      },
    },
  };
  
  return (
    <div className="App">
      <Helmet>
        <title>VF Transport - Logistic Solutions</title>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="df981e00-1393-4f9d-a6a4-2cff46830f57"
          data-blockingmode="auto"
          type="text/javascript"
        ></script>
      </Helmet>
      <header className="App-header">
        <div className="header-container">
        <a href="https://www.vftransport.eu/" target="_blank" rel="noopener noreferrer">
          <img
            src="https://i.ibb.co/fFKFFqf/Logo-VF-Transport-1-1.png"
            alt="VFTransport Logo"
            className="header-logo"
          />
        </a>
          <nav>
            <ul>
              <li>
                <a href="#hero">
                  <Home /> {content[language].header.nav.home}
                </a>
              </li>
              <li>
                <a href="#services">
                  <Settings /> {content[language].header.nav.services}
                </a>
              </li>
              <li>
                <a href="#about">
                  <Info /> {content[language].header.nav.about}
                </a>
              </li>
            </ul>
          </nav>
          <div className="language-switcher">
            <button onClick={toggleLanguage}>
              {language === 'en' ? (
                <img src="https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/bulgaria-flag-round-circle-icon.svg" alt="English Flag" className="language-flag " />
              ) : (
                <img src="https://uxwing.com/wp-content/themes/uxwing/download/flags-landmarks/uk-flag-round-circle-icon.svg" alt="Bulgarian Flag" className="language-flag" />
              )}
              {language === 'en' ? 'Switch to Bulgarian' : 'Switch to English'}
            </button>
          </div>
        </div>
      </header>

      <section id="hero" className="hero">
        <h2>{content[language].hero.title}</h2>
        <p>{content[language].hero.description[0]}</p>
        <p>{content[language].hero.description[1]}</p>
        <p>{content[language].hero.description[2]}</p>
        <a href="#about">
          <button>{content[language].hero.button}</button>
        </a>
      </section>

      <section id="services" className="services">
  <h2>{content[language].services.title}</h2>
  {/* Prima riga: prime 3 card */}
  <ul className="services-grid-top">
    {content[language].services.items.slice(0, 3).map((service, index) => (
      <li key={index}>
        <img src={service.imageUrl} alt={service.title} />
        <h3>{service.title}</h3>
        <p>{service.description}</p>
      </li>
    ))}
  </ul>
  {/* Seconda riga: ultime 2 card */}
  <ul className="services-grid-bottom">
    {content[language].services.items.slice(3).map((service, index) => (
      <li key={index + 3}>
        <img src={service.imageUrl} alt={service.title} />
        <h3>{service.title}</h3>
        <p>{service.description}</p>
      </li>
    ))}
  </ul>
</section>

      <section id="about" className="about">
        <h2>{content[language].about.title}</h2>
        <p>{content[language].about.description[0]}</p>
        <p>{content[language].about.description[1]}</p>
        <p>{content[language].about.description[2]}</p>
        <p>{content[language].about.description[3]}</p>
        <p>{content[language].about.description[4]}</p>
      </section>
      
      <section id="contact" className="contact">
        <div className="contact-card-container">
          <div className="contact-card">
            <h2>{content[language].contact.title}</h2>
            <div className="contact-info">
              <a href='mailto:info@vftransport.eu'>
                <button className="contact-btn">
                  <Mail className="icon" />
                  {content[language].contact.emailDesc} {content[language].contact.email}
                </button>
              </a>
              <a href='tel:+359 876339312'>
                <button className="contact-btn">
                  <Phone className="icon" />
                  {content[language].contact.phoneDesc} {content[language].contact.phone}
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      
      <footer className="footer">
      <div className="footer-left">
      <a href="https://www.vftransport.eu/" target="_blank" rel="noopener noreferrer">
        <img
          src="https://i.ibb.co/fFKFFqf/Logo-VF-Transport-1-1.png"
          alt="VFTransport Logo"
          className="header-logo"
        />
      </a>
      </div>
        <div className="footer-center">
          <div className="footer-info">
            <p><strong>{content[language].footer.info.title}</strong></p>
            <p>{content[language].footer.info.address}</p>
            <p>{content[language].footer.info.companyStatus}</p>
          </div>
        </div>
        <div className="footer-right">
          <ul>
            <li><a href="/privacyPolicy.html">{content[language].footer.links.privacy}</a></li>
          </ul>
        </div>
        <p className="footer-copyright">{content[language].footer.copyright}</p>
      </footer>
    </div>
  );
};

export default App;
